import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatementServiceList = _resolveComponent("StatementServiceList")!
  const _component_StatementPage = _resolveComponent("StatementPage")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.data.mode == 'serviceList')
      ? (_openBlock(), _createBlock(_component_StatementServiceList, {
          key: 0,
          onCreateStatements: _ctx.createStatements
        }, null, 8, ["onCreateStatements"]))
      : _createCommentVNode("", true),
    (_ctx.data.mode == 'statements')
      ? (_openBlock(), _createBlock(_component_StatementPage, {
          key: 1,
          statements: _ctx.data.statements,
          onBack: _ctx.openListPage
        }, null, 8, ["statements", "onBack"]))
      : _createCommentVNode("", true)
  ]))
}