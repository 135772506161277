
import { defineComponent, onMounted, reactive } from "vue";

import StatementServiceList from "@/modules/statement/StatementServiceList.vue";
import StatementPage from "@/modules/statement/StatementPage.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export interface Data {
  statements: [];
  mode: string;
}

export default defineComponent({
  name: "Statements",
  components: { StatementServiceList, StatementPage },
  setup(props) {
    let data = reactive<Data>({
      statements: [],
      mode: "serviceList",
    });

    onMounted(async () => {
      setCurrentPageTitle("Statements");
    });

    function createStatements(statements) {
      data.statements = statements;
      data.mode = "statements";
    }

    function openListPage() {
      data.mode = "serviceList";
    }

    return {
      data,
      createStatements,
      openListPage,
    };
  },
});
